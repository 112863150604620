import React, { useEffect, useRef, useState } from 'react';
import { useAppData } from '../AppContext';
import specialRocketImg from '../images/specials/rocket.png';
import specialBombImg from '../images/specials/bomb.png';
import specialShurikenImg from '../images/specials/shuriken.png';
import "./home.scss";
import dollar_coin from "./dollar_coin.png";
import rocket from "./rocket.png";
import StatBlock from '../Components/StatsMain/StatBlock';
import Navigate from '../Components/NavigateBtn/Navigate';
import Footer from '../Components/Footer/Footer';
import ThanksNiki from '../Components/ThanksNiki/ThanksNiki';
import energy_img from "./enegry.png"
import { Link, useNavigate } from 'react-router-dom';
import { getPlayerPet, sendCoinsAndEnergy } from '../http';
import { useTranslation } from 'react-i18next';
import animated from "./amimated.png"
import telelegram from "./telegramglow.png"
import gem from "./gem.png"
import Instruction from '../Components/Instruction/Instruction';
import tether from "./tether.png"
import PanameraHome from '../Components/PanameraDrop/PanameraHome';

const Popup = ({ message, onConfirm }) => {
    return (
      <div className="popup">
        <div className="popup-content">
          <h2>{message}</h2>
          <div className="popup-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="icon-check"
            >
              <path d="M20 6L9 17l-5-5" />
            </svg>
          </div>
          <button className="popup-button" onClick={onConfirm}>
            OK
          </button>
        </div>
      </div>
    );
};


export const Home = () => {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const coinImage = useRef(null);
    const energyBar = useRef(null);
    const specialBackground = useRef(null);

    const {showDropPopup, drop, isDropActive,coins, setPet, energy, energyLimit, setEnergy, clickCost, user, specialMode, Pet, passiveIncome, total_coins_earned, handleEditCoinsClick, userPhoto, energyRechargeDelay, isFirstTime, diamonds } = useAppData();
    const [username, setUsername] = useState("");
    const [leagueInfo, setLeagueInfo] = useState({ name: '', color: '' });
    const [coinsHome, setcoinsHome] = useState(coins);
    const [prevCoins, setPrevCoins] = useState(coins);
    const [energyhome, setEnergyhome] = useState(energy);
    const [prevEnergy, setPrevEnergy] = useState(energy);
    const [isClicked, setIsClicked] = useState(false);
    const coinsHomeRef = useRef(coinsHome);
    const prevCoinsRef = useRef(prevCoins);
    const energyhomeRef = useRef(energyhome);
    const prevEnergyRef = useRef(prevEnergy);
    const [show, setShow] = useState(isFirstTime);
    const [showPopup, setShowPopup] = useState(false); 

    const [clickTimes, setClickTimes] = useState([]);
    const [lastClickTime, setLastClickTime] = useState(0);
    const [interactionActive, setInteractionActive] = useState(false);
    const [clickCount, setClickCount] = useState(0);

    const overflow = 100
    document.body.style.overflowY = 'hidden'
    document.body.style.marginTop = `${overflow}px`
    document.body.style.height = window.innerHeight + overflow + "px"
    document.body.style.paddingBottom = `${overflow}px`
    window.scrollTo(0, overflow)
    const handleDropClick = () => {
        if (isDropActive) {
            setShowPopup(true); 
        } else {
            navigate("/PanameraDrop"); 
        }
    };
    useEffect(() => {
        coinsHomeRef.current = coinsHome;
        prevCoinsRef.current = prevCoins;
        energyhomeRef.current = energyhome;
        prevEnergyRef.current = prevEnergy;
    }, [coinsHome, prevCoins, energyhome, prevEnergy]);

    const leagues = [
        { name: '🎉 Friend', color: '#C7C7C7', threshold: 0 },
        { name: '🥈 Silver', color: '#91B3F8', threshold: 300000 },
        { name: '🥇 Gold', color: '#F3CD46', threshold: 1000000 },
        { name: '💎 Diamond', color: '#37CDEE', threshold: 10000000 },
        { name: '⭐ Epic', color: '#C019CD', threshold: 50000000 },
        { name: '🏅 Legendary', color: '#3940E2', threshold: 100000000 },
        { name: '🏆 Master', color: '#E73232', threshold: 1000000000 },
    ];

    useEffect(() => {
        const sortedLeagues = [...leagues].sort((a, b) => a.threshold - b.threshold);
        const currentLeague = sortedLeagues.find(league => total_coins_earned < league.threshold);
        if (currentLeague) {
            setLeagueInfo({ name: currentLeague.name, color: currentLeague.color });
        } else {
            const highestLeague = sortedLeagues[sortedLeagues.length - 1];
            setLeagueInfo({ name: highestLeague.name, color: highestLeague.color });
        }
    }, [total_coins_earned]);


    const handleClick = () => {
        setIsClicked(true);
        setTimeout(() => setIsClicked(false), 20);
    };

    const handleTouchStart = (event) => {
        event.preventDefault();
        Array.from(event.touches).forEach(touch => {
            applyTransformation(touch.clientX, touch.clientY);
            earnCoins(clickCost, touch);
            HapticFeedback()
        });
    };


    function earnCoins(income, event) {
        if (specialMode) {
            let randomFactorOptions = [0.5, 1, 1.5];
            let randomFactor = randomFactorOptions[Math.floor(Math.random() * randomFactorOptions.length)];
            income += specialMode.bonus * randomFactor;
        } else {
            income = Math.min(income, energyhome);
        }

        if (income > 0) {
            handleEditCoinsClick(income);
            setcoinsHome(coinsHome + income);
            showIncome(income, event.clientX, event.clientY);
            if (!specialMode) {
                setEnergyhome(energyhome - income);
                setEnergy(energyhome - income);
            }
        }
    }

    useEffect(() => {
        async function fetchData() {
            const data = await getPlayerPet();
            setPet(data);
        }
        fetchData();
    }, []);

    const specialData = {
        "rocket": {
            spanImage: `url(${specialRocketImg})`,
            background: 'rgba(255, 150, 26, 1)'
        },
        "bomb": {
            spanImage: `url(${specialBombImg})`,
            background: 'rgba(39, 151, 255, 1)'
        },
        "shuriken": {
            spanImage: `url(${specialShurikenImg})`,
            background: 'rgba(167, 25, 255, 1)'
        },
        "full_energy": {
            spanImage: `url(${energy_img})`,
            background: 'rgb(18 255 167 / 80%)'
        }
    };

    function showIncome(income, clientX, clientY) {
        const incomeElement = document.createElement('div');
        incomeElement.innerText = '+' + income;
        incomeElement.classList.add('income');
        incomeElement.style.left = `${clientX}px`;
        incomeElement.style.top = `${clientY + 100}px`;
        document.body.appendChild(incomeElement);

        setTimeout(() => {
            incomeElement.style.top = `${clientY - 64}px`;
        }, 10);

        setTimeout(() => {
            incomeElement.style.opacity = "0";
        }, 250);

        setTimeout(() => {
            incomeElement.remove();
        }, 1000);
    }

    useEffect(() => {
        if (energyBar.current) {
            energyBar.current.style.width = `${(energyhome / energyLimit) * 100}%`;
        }
    }, [energyhome, energyLimit]);

    useEffect(() => {
        if (specialMode) {
            if (specialMode.mode == "full_energy") {
                setEnergyhome(energyLimit)
                console.log("energyLimit setted")
            }
            const specialInfo = specialData[specialMode.mode];
            specialBackground.current.style.display = 'block';
            specialBackground.current.style.opacity = 1;
            const specialBackgroundElement = specialBackground.current.querySelector('.special-background');
            const specialSpans = specialBackground.current.querySelectorAll('.special-background span');
            specialBackgroundElement.style.background = `radial-gradient(circle at center bottom, ${specialInfo.background}, rgb(12, 12, 12) 75%)`;
            specialSpans.forEach(span => {
                span.style.backgroundImage = specialInfo.spanImage;
            });
        } else {
            specialBackground.current.style.opacity = 0;
            specialBackground.current.addEventListener('transitionend', function () {
                specialBackground.current.style.display = 'none';
            }, { once: true });
        }


    }, [specialMode]);

    const handleInteraction = (event) => {
        event.preventDefault();

        const currentTime = Date.now();
        const newClickTimes = clickTimes.filter(t => currentTime - t < 5000);

        newClickTimes.push(currentTime);
        setClickTimes(newClickTimes);

        if (newClickTimes.length > 200) {
            window.Telegram.WebApp.close();
            return;
        }

        setLastClickTime(currentTime);
        setClickCount(clickCount + 1);

        if (interactionActive) return;
        setInteractionActive(true);

        if (event.type === 'touchstart') {
            Array.from(event.touches).forEach(touch => {
                applyTransformation(touch.clientX, touch.clientY);
                earnCoins(clickCost, touch);
            });
        } else if (event.type === 'mousedown') {
            applyTransformation(event.clientX, event.clientY);
            earnCoins(clickCost, event);
        }

        setTimeout(() => {
            setInteractionActive(false);
        }, 300);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentTime = Date.now();
            const newClickTimes = clickTimes.filter(t => currentTime - t < 5000);
            setClickTimes(newClickTimes);
            setClickCount(newClickTimes.length);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [clickTimes]);

    const coinIcons = [
        "https://nikicoin.store/media/newcoin_NIKI/nikicoin00.png",
        "https://nikicoin.store/media/newcoin_NIKI/nikicoin1.png",
        "https://nikicoin.store/media/newcoin_NIKI/nikicoin2.png",
        "https://nikicoin.store/media/newcoin_NIKI/nikicoin3.png",
        "https://nikicoin.store/media/newcoin_NIKI/nikicoin4.png",
        "https://nikicoin.store/media/newcoin_NIKI/nikicoin5.png",
    ];

    const getCoinIcon = (clickCount) => {
        if (clickCount >= 300) return coinIcons[5];
        if (clickCount >= 200) return coinIcons[4];
        if (clickCount >= 100) return coinIcons[3];
        if (clickCount >= 50) return coinIcons[2];
        if (clickCount > 0) return coinIcons[1];
        return coinIcons[0];
    };

    const onTransitionEnd = () => {
        if (coinImage.current) {
            coinImage.current.style.transform = "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)";
        }
        setInteractionActive(false);
    };

    const applyTransformation = (clientX, clientY) => {
        const containerRect = coinImage.current.getBoundingClientRect();
        const centerX = (clientX - containerRect.left) / containerRect.width * 2 - 1;
        const centerY = (clientY - containerRect.top) / containerRect.height * 2 - 1;
        const rotateX = -centerY * 15;
        const rotateY = centerX * 15;
        coinImage.current.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale3d(0.98, 0.98, 0.98)`;
    };

    function openTelegramLink() {
        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openTelegramLink('https://t.me/nikicompany');
        }
    }

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            if (window.Telegram.WebApp.BackButton && window.Telegram.WebApp.BackButton.isVisible) {
                window.Telegram.WebApp.BackButton.hide();
            }
            const webAppUser = window.Telegram.WebApp.initDataUnsafe.user;
            if (webAppUser) {
                setUsername(webAppUser.first_name);
            }
        }
    }, [setUsername]);

    useEffect(() => {
        const energyUpdateInterval = setInterval(() => {
            setEnergyhome((prevEnergy) => {
                if (prevEnergy < energyLimit) {
                    return Math.min(prevEnergy + energyRechargeDelay, energyLimit);
                }
                return prevEnergy;
            });
            setEnergy((prevEnergy) => {
                if (prevEnergy < energyLimit) {
                    return Math.min(prevEnergy + energyRechargeDelay, energyLimit);
                }
                return prevEnergy;
            });
        }, 1000);
        return () => clearInterval(energyUpdateInterval);
    }, [energyLimit, energyRechargeDelay]);

    useEffect(() => {
        const coinAndEnergySyncInterval = setInterval(() => {
            const difference = coinsHomeRef.current - prevCoinsRef.current;
            const energyDiff = energyhomeRef.current - prevEnergyRef.current;

            sendCoinsAndEnergy(difference, energyDiff);

            setPrevCoins(coinsHomeRef.current);
            setPrevEnergy(energyhomeRef.current);
        }, 5000);

        return () => clearInterval(coinAndEnergySyncInterval);
    }, []);

    useEffect(() => {
        if (show) {
            console.log("123123")
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(() => setShow(false));
        }
        else {
            window.Telegram.WebApp.BackButton.hide();

        }
    }, [show]);

    const HapticFeedback = () => {
        if (window.Telegram?.WebApp?.HapticFeedback) {
          window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
        }
      };


    
    return (
        <div style={{ height: "100vh", overflow: "hidden" }}>

            {showDropPopup&& <PanameraHome/> }

            {!show &&
                (Pet ? (
                    <Navigate navigateTo="/MainPet" state={{ position: "right" }} replace />
                ) : (
                    <Navigate navigateTo="/choose_pet" state={{ position: "right" }} replace />
                ))
            }



            <div className="container">
                <div className="header">
                    <div className="player-team-info player-team-join" onClick={()=>navigate("/pump")}>
                    </div>
                    <div className='settings' onClick={() => navigate("/settings")}>
                        <img src={userPhoto} className='setting_logo' alt="User Photo" />
                        <div className='setting_name'>
                            {username}
                        </div>
                    </div>
                </div>
                
                {passiveIncome > 0 && <ThanksNiki coins={passiveIncome} />}
                <div className='outer-container_home'>
                    <div className='container_glow_home'>
                        <div className="effect-background" ref={specialBackground}>
                            <div className="special-background">
                                <span style={{ '--i': 16 }}></span>
                                <span style={{ '--i': 11 }}></span>
                                <span style={{ '--i': 19 }}></span>
                                <span style={{ '--i': 13 }}></span>
                                <span style={{ '--i': 15 }}></span>
                                <span style={{ '--i': 20 }}></span>
                                <span style={{ '--i': 12 }}></span>
                                <span style={{ '--i': 17 }}></span>
                            </div>
                        </div>
                        <div className="main-content">
                            <div className='column'>
                                <StatBlock />
                                <div className='row_home'>
                                    <div className="stats">
                                        <img src={dollar_coin} className="stats-coin" alt="Coin icon" />
                                        <div id="balance" className="coin-count">{coins.toLocaleString('en-US')}</div>
                                    </div>

                                    <img src={telelegram} className='tg_btn' onClick={openTelegramLink}></img>
                                </div>
                                <div className="stats-container">
                                    <div className="stats gem_back" onClick={() => setShow(true)}>
                                        <div id="balance" className="gem_count">{diamonds}</div>
                                        <img src={gem} className="stats_gem" alt="Coin icon" />
                                    </div>
                                    <div
                                        className="stats usd-back"
                                        style={{ background: isDropActive ? "#1C9E78" : undefined }}
                                    >
                                        {isDropActive ? (
                                            <span
                                                className="warning_icon"
                                                style={{ background: isDropActive ? "#1C9E78" : undefined }}
                                            >
                                                <svg
                                                    width="45"
                                                    height="45"
                                                    viewBox="0 0 45 45"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M22.0833 0C9.91542 0 0 9.91542 0 22.0833C0 34.2513 9.91542 44.1667 22.0833 44.1667C34.2513 44.1667 44.1667 34.2513 44.1667 22.0833C44.1667 9.91542 34.2513 0 22.0833 0ZM30.0113 18.9696L22.2379 26.7429C21.9067 27.0742 21.4871 27.2288 21.0675 27.2288C20.6479 27.2288 20.2063 27.0742 19.8971 26.7429L16.0104 22.8563C15.3479 22.2158 15.3479 21.1558 16.0104 20.5154C16.6508 19.875 17.7108 19.875 18.3513 20.5154L21.0675 23.2317L27.6704 16.6287C28.3108 15.9883 29.3488 15.9883 30.0113 16.6287C30.6517 17.2692 30.6517 18.3292 30.0113 18.9696Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </span>
                                        ) : (
                                            <span className="warning_icon">⚠</span>
                                        )}

                                        <div
                                            id="usd-balance"
                                            className="usd-count"
                                            onClick={handleDropClick}
                                        >
                                            <span>{drop}$</span>
                                        </div>
                                        <img src={tether} alt="Tether icon" />
                                    </div>
                                </div>

                                {showPopup && (
                                    <Popup
                                        message={t("messageDrop")}
                                        onConfirm={() => setShowPopup(false)} 
                                    />
                                )}
                                
                            </div>

                            <div id="playZone" className="play-zone">
                                <div className="gameplay-coin-glow" style={{ background: `radial-gradient(${leagueInfo.color}, rgb(255 255 255 / 0%) 66%)` }}></div>
                                <div className="gameplay-coin" onTouchStart={handleTouchStart} ref={coinImage}>
                                    <img
                                        src={getCoinIcon(clickCount)}
                                        alt="Coin"
                                        draggable="false"
                                        onTouchStart={handleTouchStart}
                                        onPointerDown={handleInteraction}
                                        onTransitionEnd={onTransitionEnd}
                                        onClick={handleClick}
                                        style={{
                                            transition: 'transform 0.1s ease-out',
                                            transform: isClicked ? 'perspective(1000px)' : 'perspective(0px)',
                                        }}
                                    />
                                    {specialMode && <img className="amimation_boost_img" src={animated}></img>}
                                </div>
                            </div>
                            <div className='column'>
                                <div onClick={() => navigate(`/league/${leagueInfo.name.replace(/[^a-zA-Z0-9]/g, '')}`)} className="league_btn">
                                    <span className="league_text">{t('home.league', { league: leagueInfo.name })}</span>
                                    <svg width="24" height="15" viewBox="0 0 24 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 45L21 24L3 3" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>

                                <div className="energy-wrapper">
                                    <img src={energy_img} className="energy-icon" alt="Energy icon" />
                                    <div className="energy-counter">{energyhome}</div>
                                    <div className="energy-bar">
                                        <div className="energy" ref={energyBar}></div>
                                    </div>
                                    <Link to="/boosts" className="boost_btn">
                                        <img src={rocket} className="boost_btn_img" alt="Boost button" />
                                        {t('home.boost')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {show && <Instruction setShow={setShow} />}
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
};
