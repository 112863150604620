import React, { useState } from 'react';
import './settings.scss';
import lenguague from './lenguague.png';
import wallet from './wallet.png';
import { useTranslation } from 'react-i18next';
import Back from '../Back';
import { useNavigate } from 'react-router-dom';
import icon1 from "./icon1.png"
import icon3 from "./icon3.png"
import { claimDailyRewords } from "../../http"
import { useAppData } from "../../AppContext"
import { TonConnectButton, useTonAddress } from '@tonconnect/ui-react';
import BuyCoin from '../BuyCoin/BuyCoin';
import panamera from "./panamera_small.png"
import tether from "./tether.png"
function Settings() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const [selectedDaily, setSelectedDaily] = useState(null);
    const { drop,isDropActive } = useAppData()

    const dailys = [
        {
            "day": 1,
            "coins_reward": 3250000,
            "diamonds_reward": 0,
            "status": "Claimed"
        },
        {
            "day": 2,
            "coins_reward": 3250000,
            "diamonds_reward": 0,
            "status": "Available"
        },
        {
            "day": 3,
            "coins_reward": 3250000,
            "diamonds_reward": 25,
            "status": "Noavailable"
        },
        {
            "day": 4,
            "coins_reward": 3250000,
            "diamonds_reward": 0,
            "status": "Noavailable"
        },
        {
            "day": 5,
            "coins_reward": 3250000,
            "diamonds_reward": 50,
            "status": "Noavailable"
        },
        {
            "day": 6,
            "coins_reward": 3250000,
            "diamonds_reward": 0,
            "status": "Noavailable"
        },
        {
            "day": 7,
            "coins_reward": 3250000,
            "diamonds_reward": 75,
            "status": "Noavailable"
        },
        {
            "day": 8,
            "coins_reward": 3250000,
            "diamonds_reward": 0,
            "status": "Noavailable"
        },
        {
            "day": 9,
            "coins_reward": 3250000,
            "diamonds_reward": 100,
            "status": "Noavailable"
        },
        {
            "day": 10,
            "coins_reward": 3250000,
            "diamonds_reward": 150,
            "status": "Noavailable"
        },
        {
            "day": 11,
            "coins_reward": 3250000,
            "diamonds_reward": 0,
            "status": "Noavailable"
        },
        {
            "day": 12,
            "coins_reward": 3250000,
            "diamonds_reward": 300,
            "status": "Noavailable"
        }
    ]

    const areAllRewardsUnavailable = dailys.every(dayReward => dayReward.status !== 'Available');

    const handleClaimReword = async () => {
        try {
            await claimDailyRewords();
            // const checkedValue = result.checked;
            // if (status) {
            //     if(checkedValue) {
            //         await completeTask(id);
            //         handleEditCoins(task__coins_reward);
            //     } 
            // } else {
            //     seeTask(id);
            // }
            handleCloseModal();
        } catch (error) {
            console.error("Failed to clain reword", error);
        }
    }
    const handleCloseModal = () => {
        setSelectedDaily(null);
    };
    function interpolateColor(color1, color2, ratio) {
        const hexToRgb = hex => {
            let r = parseInt(hex.slice(0, 2), 16);
            let g = parseInt(hex.slice(2, 4), 16);
            let b = parseInt(hex.slice(4, 6), 16);
            return { r, g, b };
        };

        const rgbToHex = ({ r, g, b }) =>
            '#' + [r, g, b].map(x => x.toString(16).padStart(2, '0')).join('');

        const start = hexToRgb(color1);
        const end = hexToRgb(color2);

        const r = Math.round(start.r + (end.r - start.r) * ratio);
        const g = Math.round(start.g + (end.g - start.g) * ratio);
        const b = Math.round(start.b + (end.b - start.b) * ratio);

        return rgbToHex({ r, g, b });
    }

    function getGemColor(gems) {
        if (gems > 0 && gems <= 25) {
            return "#009DFF";
        } else if (gems <= 50) {
            return interpolateColor("1866FF", "062766", (gems - 25) / 25);
        } else if (gems <= 75) {
            return interpolateColor("BD00FF", "062766", (gems - 50) / 25);
        } else if (gems <= 100) {
            return interpolateColor("FF8A00", "412B00", (gems - 75) / 25);
        } else if (gems <= 150) {
            return interpolateColor("61FF00", "215800", (gems - 100) / 50);
        } else if (gems <= 300) {
            return interpolateColor("00FF85", "03462A", (gems - 150) / 150);
        }
    }

    const formatNumber = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(0) + 'B';
        } else if (num >= 1000000) {
            return (num / 1000000).toFixed(0) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(0).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 3 }) + 'K';
        }
        return num;
    };

    const tonAddress = useTonAddress();  
    const [copied, setCopied] = useState(false);

    const handleClick = () => {
      navigator.clipboard.writeText("0xa78713fe193060e9f475ba6baf4d7d3b44d2f93a").then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);  
      });
    };
    const formatAddress = (address) => {
        if (!address) return t('none');
        return `${address.slice(0, 6)}....${address.slice(-4)}`;
    };

    return (
        <div>

            {!show&&<div className='settings_text'>{t('settings')}</div>}
            <div className='height100'>
            <Back />
            {show ? 

                <BuyCoin setShow={setShow} /> 
            : 
                <>
                        <div className='outer-container_settings'>
                        <div className='container_glow_settings'>
                            <div className="settings-container">
                                <div className="settings-block">
                                    <div className="settings-item" onClick={() => navigate("/lenguague_picker")}>
                                        <div className="settings-text">
                                            <div className="settings-title">{t('changeLanguage')}</div>
                                            <div className="settings-subtitle">{i18n.language}</div>
                                        </div>
                                        <img className="settings-icon" src={lenguague} alt="language icon" />
                                    </div>

                                    <button className="settings-item">
                                        <div className="settings-text">
                                            <div className="settings-title">{t('connectWallet')}</div>
                                            <div className="settings-subtitle">
                                                {tonAddress ? formatAddress(tonAddress) : t('none')}
                                            </div>
                                        </div>
                                        <img className="settings-icon" src={wallet} alt="wallet icon" />
                                        <TonConnectButton
                                            style={{ background: "none", color: "inherit" }}
                                        ></TonConnectButton> 
                                    </button>

                                    <button className={`copy-button ${copied ? 'copied' : ''}`} onClick={handleClick}>
                                        <div>
                                            <div className='title_copy'>$NIKI Contact ETH</div>
                                            <div>{copied ? 'Адрес скопирован!' : '0xa78713fe1930.....7d3b44d2f93a '}</div>
                                        </div>
                                        <div className='btn_copy'>Copy</div>
                                    
                                    </button>
                                    
                                    <div className="promo-container-buy" onClick={()=>setShow(true)}>
                                        <p className="promo-text">BUY $NIKI COIN</p>
                                        <div className="promo-button_buy" target="_blank" rel="noopener noreferrer" >
                                            Buy
                                            <img src={icon3} className='icon2_small'/>
                                            <img src={icon1} className='icon1_small'/>
                                        </div>
                                    </div>

                                    <div className='drop_btn' onClick={()=>navigate("/PanameraDrop")}>
                                        <div className='container_activate'>
                                            <div className='text_sttings'>{t("activateDropButton")}</div>
                                            <div
                                                className="stats usd-back"
                                                style={{ background: isDropActive ? "#1C9E78" : undefined }}
                                            >
                                                {isDropActive ? (
                                                    <span
                                                        className="warning_icon"
                                                        style={{ background: isDropActive ? "#1C9E78" : undefined }}
                                                    >
                                                        <svg
                                                            width="45"
                                                            height="45"
                                                            viewBox="0 0 45 45"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M22.0833 0C9.91542 0 0 9.91542 0 22.0833C0 34.2513 9.91542 44.1667 22.0833 44.1667C34.2513 44.1667 44.1667 34.2513 44.1667 22.0833C44.1667 9.91542 34.2513 0 22.0833 0ZM30.0113 18.9696L22.2379 26.7429C21.9067 27.0742 21.4871 27.2288 21.0675 27.2288C20.6479 27.2288 20.2063 27.0742 19.8971 26.7429L16.0104 22.8563C15.3479 22.2158 15.3479 21.1558 16.0104 20.5154C16.6508 19.875 17.7108 19.875 18.3513 20.5154L21.0675 23.2317L27.6704 16.6287C28.3108 15.9883 29.3488 15.9883 30.0113 16.6287C30.6517 17.2692 30.6517 18.3292 30.0113 18.9696Z"
                                                                fill="white"
                                                            />
                                                        </svg>
                                                    </span>
                                                ) : (
                                                    <span className="warning_icon">⚠</span>
                                                )}

                                                <div
                                                    id="usd-balance"
                                                    className="usd-count"
                                                >
                                                    <span>{drop}$</span>
                                                </div>
                                                <img src={tether} alt="Tether icon" />
                                            </div>

                                        </div>
                                        <img style={{width:"22%"}} src={panamera}/>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
           
           


        </div>
        </div>

       
    );
}

export default Settings;
